import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Alert, Progress, Icon } from '../../components/library'
import hexToRgbA from '../../utils/hexToRgbA'

function actions() {
    return (
        <LibraryWrapper>
            <Alert 
                title="Get Started"
                icon={
                    <Progress step={1} total={4} color="light" />
                }
                to="/components"
            >
                    <ol>
                        <li>Pair your wearable</li>
                        <li>Select goals</li>
                        <li>Asthma Control Test</li>
                        <li>Spirometry Test</li>
                    </ol>
                </Alert>
            <hr />
            <Alert 
                title="Daily Check-In"
                icon={<Icon name="watch" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="lg" />}
                to="/components"
                color="secondary"
            >
                <span>It's time for an asthma check-in with James.</span>
            </Alert>
            <hr />
            <Alert 
                title="Week 2 To Do"
                icon={
                    <Progress step={0} total={3} color="light" />
                }
                to="/components"
            >
                    <ol>
                        <li>Goal check-in</li>
                        <li>C-ACT</li>
                        <li>Spirometry Test</li>
                    </ol>
            </Alert>
        </LibraryWrapper>
    )
}
export default actions
